<template>
  <v-container id="prefatture" style="height: 100%">
    <v-row>
      <v-col cols="12">
        <v-data-table
          :loading="!datiCaricati"
          loading-text="Loading..."
          :headers="headers"
          :items="sorted"
          :items-per-page="itemsPerPage"
          no-data-text="Nessun dato disponibile"
          no-results-text="Nessun risultato"
          fixed-header
          :height="
            tipoUtente == 'S' || tipoUtente == 'A'
              ? 'calc(100vh - 375px)'
              : 'calc(100vh - 305px)'
          "
          :single-expand="true"
          item-key="numPrefattura"
          show-expand
          :footer-props="{
            'items-per-page-options': [15, 25, 50, -1],
            'items-per-page-text': 'Elementi per pagina',
            'items-per-page-all-text': 'Tutti',
          }"
          @current-items="getData"
        >
          <template v-slot:top>
            <v-card-title>
              <p class="align-self-start">Elenco prefatture</p>

              <v-spacer></v-spacer>
              <div>
                <div class="d-flex">
                  <v-combobox
                    v-if="tipoUtente == 'S' || tipoUtente == 'A'"
                    v-model="codiceFo"
                    :items="fornitori"
                    label="Seleziona fornitore"
                    @change="getPrefatture()"
                    style="width: 440px"
                    class="mr-4"
                    :search-input.sync="search"
                  >
                  </v-combobox>
                  <v-select
                    v-model="mese"
                    :items="mesi"
                    label="Seleziona mese/anno"
                    item-text="text"
                    item-value="value"
                    @change="getPrefatture()"
                    style="max-width: 180px"
                  ></v-select>
                </div>
                <div v-if="tipoUtente == 'S' || tipoUtente == 'A'">
                  <v-form
                    ref="form"
                    @submit.prevent="cercaPrefattura()"
                    class="d-flex align-center"
                    :v-model="
                      prefattura != '' && prefattura != null && valid
                        ? true
                        : false
                    "
                  >
                    <v-text-field
                      v-model="prefattura"
                      label="Numero prefattura"
                      clearable
                      maxlength="9"
                      counter="9"
                      @keypress="isInteger($event)"
                      class="mr-4"
                      inputmode="numeric"
                      pattern="[0-9]*"
                      style="max-width: 180px"
                      :rules="[
                        (v) =>
                          !v ||
                          v.length == 9 ||
                          'Il numero prefattura deve contenere 9 cifre',
                        (v) => /^(\s*|\d+)$/.test(v) || 'Inserire solo numeri',
                      ]"
                    ></v-text-field>
                    <v-btn
                      color="primary"
                      rounded
                      small
                      :disabled="
                        prefattura != '' && prefattura != null && !valid
                          ? false
                          : true
                      "
                      type="submit"
                    >
                      Cerca
                    </v-btn>
                  </v-form>
                </div>
              </div>
            </v-card-title>
          </template>

          <template v-slot:[`item.tipoServizio`]="{}"> </template>

          <template v-slot:[`item.imponibile`]="{ item }">
            {{ totaleImponibile(item.dettaglio) }}
          </template>

          <template v-slot:[`item.iva`]="{ item }">
            {{ totaleImposta(item.dettaglio) }}
          </template>

          <template v-slot:[`item.totale`]="{ item }">
            <b>{{ totalone(item.dettaglio) }}</b>
          </template>

          <template v-slot:expanded-item="{ item }">
            <td :colspan="1" style="background-color: lightgrey">
              <p v-for="(el, i) in item.dettaglio" :key="i">
                {{ getTipo(el.tipoServizio) }}
              </p>
            </td>
            <td :colspan="1" style="background-color: lightgrey">
              <p v-for="(el, i) in item.dettaglio" :key="i">
                {{ el.fornitore }}
              </p>
            </td>
            <td :colspan="1" style="background-color: lightgrey">
              <p v-for="(el, i) in item.dettaglio" :key="i">
                {{ el.ragioneSociale }}
              </p>
            </td>
            <td :colspan="1" style="background-color: lightgrey">
              <p v-for="(el, i) in item.dettaglio" :key="i">
                {{ el.piva }}
              </p>
            </td>
            <td :colspan="1" style="background-color: lightgrey">
              <p v-for="(el, i) in item.dettaglio" :key="i">
                {{ el.numPrefattura }}
              </p>
            </td>
            <td :colspan="1" style="background-color: lightgrey">
              <p v-for="(el, i) in item.dettaglio" :key="i">
                {{ el.dataFinale }}
              </p>
            </td>
            <td :colspan="1" style="background-color: lightgrey">
              <p v-for="(el, i) in item.dettaglio" :key="i">
                {{ el.automFil }}
              </p>
            </td>
            <td :colspan="1" style="background-color: lightgrey">
              <p v-for="(el, i) in item.dettaglio" :key="i">
                {{ el.automCod }}
              </p>
            </td>
            <td :colspan="1" style="background-color: lightgrey">
              <p v-for="(el, i) in item.dettaglio" :key="i">
                {{ formatNumber(el.imponibile, 2) }}
              </p>
            </td>
            <td :colspan="1" style="background-color: lightgrey">
              <p v-for="(el, i) in item.dettaglio" :key="i">
                {{ formatNumber(el.iva, 2) }}
              </p>
            </td>
            <!-- <td :colspan="1" style="background-color: lightgrey">
              <p v-for="(el, i) in item.dettaglio" :key="i">
                {{ el.scontoPagImporto }}
              </p>
            </td>
            <td :colspan="1" style="background-color: lightgrey">
              <p v-for="(el, i) in item.dettaglio" :key="i">
                {{ el.canonePalmare }}
              </p>
            </td> -->
            <td :colspan="1" style="background-color: lightgrey">
              <p v-for="(el, i) in item.dettaglio" :key="i">
                {{ formatNumber(el.totale, 2) }}
              </p>
            </td>
            <td :colspan="2" style="background-color: lightgrey">
              <p v-for="(el, i) in item.dettaglio" :key="i">
                <v-icon
                  v-if="el.nomeFile != ''"
                  @click="downloadPDF(item, el)"
                  small
                  >mdi-file</v-icon
                >
                <v-icon v-if="el.nomeFile == ''"></v-icon>
              </p>
            </td>
          </template>

          <template v-slot:[`footer.prepend`]>
            <v-btn
              @click="downloadCSV()"
              text
              class="ml-4"
              color="grey darken-3"
            >
              Esporta
              <v-icon right> mdi-download </v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <!-- EXPORT DIALOG -->
        <v-dialog v-model="dialogCSV" max-width="500px">
          <v-card>
            <v-card-title>Download CSV</v-card-title>
            <v-card-text>
              <div class="mt-4">
                <p style="display: inline">File .csv pronto!</p>
                <a
                  :download="'Prefatture-' + dateNow + '.csv'"
                  :href="fileCSV"
                  style="text-decoration: none; display: inline"
                  class="ml-4"
                >
                  <v-btn @click="dialogCSV = false" color="primary">
                    Download
                    <v-icon right> mdi-download </v-icon>
                  </v-btn>
                </a>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialogCSV = false"
                >Chiudi</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import router from "../router";

export default {
  name: "Prefatture",
  data: () => ({
    prefatture: [],
    itemsPerPage: 15,
    headers: [
      {
        text: "Tipo",
        align: "start",
        value: "tipoServizio",
        sortable: false,
      },
      {
        text: "Cod. Fornitore",
        value: "fornitore",
        sortable: false,
      },
      {
        text: "Ragione Sociale",
        value: "ragioneSociale",
        sortable: false,
      },
      {
        text: "Partita IVA",
        value: "piva",
        sortable: false,
      },
      {
        text: "Numero prefattura",
        value: "numPrefattura",
        sortable: false,
      },
      {
        text: "Data",
        value: "dataFinale",
        sortable: false,
      },
      {
        text: "Filiale",
        value: "automFil",
        sortable: false,
      },
      {
        text: "Autista",
        value: "automCod",
        sortable: false,
      },
      {
        text: "Imponibile",
        value: "imponibile",
        sortable: false,
      },
      {
        text: "Imposta",
        value: "iva",
        sortable: false,
      },
      // {
      //   text: "Sconto",
      //   value: "scontoPagImporto",
      //   sortable: false,
      // },
      // {
      //   text: "Canone palmare",
      //   value: "canonePalmare",
      //   sortable: false,
      // },
      {
        text: "Totale",
        value: "totale",
        sortable: false,
      },
      {
        text: "",
        value: "nomeFile",
        sortable: false,
      },
      { text: "", value: "data-table-expand", sortable: false },
    ],
    search: "",
    buttonLoading: false,
    datiCaricati: true,
    sorted: [],
    dialogCSV: false,
    currentItems: [],
    fileCSV: null,
    dateNow: "",
    codiceFo: null,
    fornitori: [],
    mesi: [],
    mese: "",
    tipoUtente: "",
    codiciLimite: [],
    prefattura: "",
    valid: false,
  }),
  mounted() {
    this.$store.state.loading = true;
    // localStorage.setItem("tipologia", "A");
    this.tipoUtente = localStorage.getItem("tipologia");
    this.getMonths();

    if (this.tipoUtente != "A" && this.tipoUtente != "S") {
      this.getPrefatture();
    } else {
      this.fornitori = JSON.parse(localStorage.getItem("fornitoriPrefatture"));
      this.fornitori.unshift("Tutti");
      // this.fornitori = ["123", "543"];
      this.$store.state.loading = false;
    }

  },
  methods: {
    getMonths() {
      var currentMonth = new Date().getMonth();
      var months = [
        {
          text: "Gennaio",
          value: 0,
        },
        {
          text: "Febbraio",
          value: 1,
        },
        {
          text: "Marzo",
          value: 2,
        },
        {
          text: "Aprile",
          value: 3,
        },
        {
          text: "Maggio",
          value: 4,
        },
        {
          text: "Giugno",
          value: 5,
        },
        {
          text: "Luglio",
          value: 6,
        },
        {
          text: "Agosto",
          value: 7,
        },
        {
          text: "Settembre",
          value: 8,
        },
        {
          text: "Ottobre",
          value: 9,
        },
        {
          text: "Novembre",
          value: 10,
        },
        {
          text: "Dicembre",
          value: 11,
        },
      ];
      var currentMonthName = "";
      var lastYear = [];
      var thisYear = [];

      months.forEach((el) => {
        var text = "";
        if (el.value < currentMonth && el.value > currentMonth - 6) {
          text = `${el.text} ${new Date().getFullYear()}`;
          thisYear.push({
            text: text,
            value: `${el.value}-${new Date().getFullYear()}`,
          });
        }
        if (el.value > currentMonth && el.value > currentMonth + 6) {
          text = `${el.text} ${new Date().getFullYear() - 1}`;
          lastYear.push({
            text: text,
            value: `${el.value}-${new Date().getFullYear() - 1}`,
          });
        }
        if (el.value == currentMonth) {
          currentMonthName = el.text;
        }
      });

      thisYear.push({
        text: `${currentMonthName} ${new Date().getFullYear()}`,
        value: `${currentMonth}-${new Date().getFullYear()}`,
      });

      lastYear = lastYear.sort(
        (a, b) => a.value.split("-")[0] - b.value.split("-")[0]
      );
      thisYear = thisYear.sort(
        (a, b) => a.value.split("-")[0] - b.value.split("-")[0]
      );
      this.mesi = [...lastYear, ...thisYear];
      this.mese = `${currentMonth}-${new Date().getFullYear()}`;
    },
    formatData(data) {
      this.prefatture = data;

      this.prefatture.map((el) => {
        var anno = el.dataFinale.toString().substr(0, 4);
        var mese = el.dataFinale.toString().substr(4, 2);
        var giorno = el.dataFinale.toString().substr(6, 2);
        el.dataFinale = `${giorno}/${mese}/${anno}`;
      });

      var codiciPrefatture = [];
      this.prefatture.forEach((el) => {
        if (!codiciPrefatture.includes(el.numPrefattura)) {
          codiciPrefatture.push(el.numPrefattura);
        }
      });

      this.prefatture.forEach((item) => {
        if (this.sorted.some((el) => el.numPrefattura == item.numPrefattura)) {
          this.sorted.forEach((el) => {
            if (el.numPrefattura == item.numPrefattura) {
              // Modifica di escludere IVA per fornitore 157757 (Firtigu) richiesta da Iolanda d'Antuono e Panzarea l'8 Gennaio 2024
              // Modifica di escludere IVA per fornitore 156822 (MC TRASPORTI) richiesta da Iolanda d'Antuono e Panzarea 15 Ottobre 2024
              if (item.fornitore == 157757 || item.fornitore == 156822) {
                el["dettaglio"].push({
                  tipoServizio: item.tipoServizio,
                  automCod: item.automCod,
                  automFil: item.automFil,
                  imponibile: item.imponibile,
                  iva: "",
                  totale: item.imponibile,
                  nomeFile: item.nomeFile,
                });
              } else {
                el["dettaglio"].push({
                  tipoServizio: item.tipoServizio,
                  automCod: item.automCod,
                  automFil: item.automFil,
                  imponibile: item.imponibile,
                  iva: item.iva,
                  totale: item.totale,
                  nomeFile: item.nomeFile,
                });
              }
            }
          });
        } else {
          // Modifica di escludere IVA per fornitore 157757 (Firtigu) richiesta da Iolanda d'Antuono e Panzarea l'8 Gennaio 2024
          // Modifica di escludere IVA per fornitore 156822 (MC TRASPORTI) richiesta da Iolanda d'Antuono e Panzarea 15 Ottobre 2024
          if (item.fornitore == 157757 || item.fornitore == 156822) {
            this.sorted.push({
              fornitore: item.fornitore,
              ragioneSociale: item.ragioneSociale,
              piva: item.piva,
              numPrefattura: item.numPrefattura,
              dataFinale: item.dataFinale,
              automFil: item.automFil,
              imponibile: "",
              iva: "",
              scontoPagImporto: item.scontoPagImporto,
              canonePalmare: item.canonePalmare,
              dettaglio: [
                {
                  tipoServizio: item.tipoServizio,
                  automCod: item.automCod,
                  automFil: item.automFil,
                  imponibile: item.imponibile,
                  iva: "",
                  totale: item.imponibile,
                  nomeFile: item.nomeFile,
                },
              ],
            });
          } else {
            this.sorted.push({
              fornitore: item.fornitore,
              ragioneSociale: item.ragioneSociale,
              piva: item.piva,
              numPrefattura: item.numPrefattura,
              dataFinale: item.dataFinale,
              automFil: item.automFil,
              imponibile: "",
              iva: "",
              scontoPagImporto: item.scontoPagImporto,
              canonePalmare: item.canonePalmare,
              dettaglio: [
                {
                  tipoServizio: item.tipoServizio,
                  automCod: item.automCod,
                  automFil: item.automFil,
                  imponibile: item.imponibile,
                  iva: item.iva,
                  totale: item.totale,
                  nomeFile: item.nomeFile,
                },
              ],
            });
          }
        }
      });

      this.sorted.sort((a, b) => b.numPrefattura - a.numPrefattura);

      // for (let i = 0; i < this.sorted.length - 1; i++) {
      //   var month1 = this.sorted[i]["dataFinale"].split("/")[1];
      //   var month2 = this.sorted[i + 1]["dataFinale"].split("/")[1];

      //   if (month1 != month2) {
      //     this.codiciLimite.push(this.sorted[i + 1]["numPrefattura"]);
      //   }
      // }

      this.datiCaricati = true;
    },
    getPrefatture() {
      this.prefattura = "";
      this.prefatture = [];
      this.sorted = [];
      this.datiCaricati = false;
      var codice = "";
      if (this.codiceFo == null) {
        codice = "";
      } else if (this.codiceFo == "Tutti") {
        codice = "admin";
      } else {
        codice = this.codiceFo.value;
      }
      var month = parseInt(this.mese.split("-")[0]) + 1;
      this.axios
        .post(`${this.$store.state.url_connessione}pffor10f`, {
          codice: codice,
          mese: month.toString().padStart(2, "0"),
          anno: this.mese.split("-")[1],
        })
        .then((response) => {
          this.$store.state.loading = false;
          this.formatData(response.data);
        })
        .catch((error) => {
          this.datiCaricati = true;
          this.$store.state.loading = false;
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout");
              this.$root.Snackbar.show({
                text: error.response.data,
                type: "error",
              });
            }
          }
        });
    },
    // drawline(item) {
    //   if (this.codiciLimite.includes(item.numPrefattura)) {
    //     return "delimiter";
    //   }
    //   console.log(item);
    // },
    cercaPrefattura() {
      this.prefatture = [];
      this.sorted = [];
      this.datiCaricati = false;
      this.codiceFo = "";
      this.getMonths();

      this.axios
        .post(`${this.$store.state.url_connessione}pffor10f/cercaPrefattura`, {
          prefattura: this.prefattura,
        })
        .then((response) => {
          this.$store.state.loading = false;
          if (response.data.length == 0) {
            this.$root.Snackbar.show({
              text: "Prefattura non trovata",
              type: "warning",
            });
            this.datiCaricati = true;
          } else {
            this.formatData(response.data);
          }
        })
        .catch((error) => {
          this.datiCaricati = true;
          this.$store.state.loading = false;
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout");
              this.$root.Snackbar.show({
                text: error.response.data,
                type: "error",
              });
            }
            if (error.response.status == 404) {
              this.$root.Snackbar.show({
                text: error.response.data,
                type: "error",
              });
            }
          }
        });
    },
    isInteger(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    filterAll(valore, search, item) {
      var results = [];
      var columns = [];

      this.headers.forEach((element) => {
        columns.push(element.value);
      });

      Object.entries(item).forEach(([key, value]) => {
        if (typeof value === "number") {
          value = value.toString().replace(".", ",");
        }

        if (
          value != undefined &&
          value != null &&
          value != "" &&
          columns.includes(key) &&
          search != null &&
          typeof value === "string" &&
          value.toLowerCase().includes(search.toLowerCase())
        ) {
          results.push("true");
        }

        if (key == "dettaglio") {
          value.forEach((el) => {
            Object.entries(el).forEach(([key, value]) => {
              if (typeof value === "number") {
                value = value.toString().replace(".", ",");
              }

              if (
                value != undefined &&
                value != null &&
                value != "" &&
                columns.includes(key) &&
                search != null &&
                typeof value === "string" &&
                value.toLowerCase().includes(search.toLowerCase())
              ) {
                results.push("true");
              }
            });
          });
        }
      });
      return results.includes("true");
    },
    getTipo(tipo) {
      if (tipo == 1) {
        return "Ritiri";
      } else if (tipo == 2 || tipo == 3) {
        return "Consegne";
      } else {
        return "Foglio cos.";
      }
    },
    formatNumber(val, dec) {
      return val.toLocaleString("it-IT", {
        minimumFractionDigits: dec,
        maximumFractionDigits: dec,
      });
    },
    totaleImponibile(dettaglio) {
      var somma = 0;

      dettaglio.forEach((el) => {
        somma += el.imponibile;
      });
      return somma.toLocaleString("it-IT", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    totaleImposta(dettaglio) {
      var somma = 0;

      dettaglio.forEach((el) => {
        somma += el.iva;
      });
      return somma.toLocaleString("it-IT", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    totalone(dettaglio) {
      var somma = 0;

      dettaglio.forEach((el) => {
        somma += el.totale;
      });
      return somma.toLocaleString("it-IT", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    downloadPDF(item, el) {
      this.axios
        .post(`${this.$store.state.url_connessione}Pffor10f/pdf`, {
          nomeFile: el.nomeFile,
          dataFinale:
            item.dataFinale.substr(6, 4) + item.dataFinale.substr(3, 2),
          tipoServizio: el.tipoServizio,
        })
        .then((res) => {
          if (res.data === null || res.data === undefined || res.data === "") {
            return;
          } else {
            var pdf = res.data;
            var decodedString = atob(pdf);
            // console.log(decodedString, (Buffer.from(pdf, "base64")).toString());
            // var pdfTemp = Buffer.from(pdf, "base64");
            // var decodedString = `data:application/pdf;base64,${pdfTemp.toString()}`;
            var byteCharacters = decodedString;
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {
              type: "application/pdf",
            });
            const fileObjectURL = URL.createObjectURL(blob);
            window.open(fileObjectURL);
          }
        })
        .catch((error) => {
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout");
              this.$root.Snackbar.show({
                text: error.response.data,
                type: "error",
              });
            }
          }
        });
    },
    getData(e) {
      this.currentItems = e;
    },
    downloadCSV() {
      this.dialogCSV = true;
      var csv = "";
      var itemsBuffer = this.itemsPerPage;
      this.itemsPerPage = -1;

      this.$nextTick(() => {
        csv += `"";"Tipo";"Cod. fornitore";"Ragione Sociale";"Partita IVA";"Numero prefattura";"Data";"Filiale";"Autista";"Imponibile";"Imposta";"Totale"\n`;
        this.currentItems.forEach((row) => {
          csv += `"P";"";"${row.fornitore}";"${row.ragioneSociale}";"${
            row.piva
          }";"${row.numPrefattura}";"${row.dataFinale}";"${
            row.automFil
          }";"";"${this.totaleImponibile(row.dettaglio)}";"${this.totaleImposta(
            row.dettaglio
          )}";"${this.totalone(row.dettaglio)}";\n`;
          row.dettaglio.forEach((el) => {
            csv += `"";"${this.getTipo(el.tipoServizio)}";"";"";"";"";"";"${
              el.automFil
            }";"${el.automCod}";"${this.formatNumber(
              el.imponibile,
              2
            )}";"${this.formatNumber(el.iva, 2)}";"${this.formatNumber(
              el.totale,
              2
            )}";\n`;
          });
        });

        var universalBOM = "\uFEFF";
        var csvData = new Blob([universalBOM + csv], {
          type: "text/csv;charset=utf-8",
        });
        this.fileCSV = URL.createObjectURL(csvData);
        var d = new Date();
        this.dateNow =
          d.getFullYear() +
          "" +
          (d.getMonth() + 1) +
          "" +
          d.getDate() +
          "" +
          d.getHours() +
          "" +
          d.getMinutes() +
          "" +
          d.getSeconds();
        this.itemsPerPage = itemsBuffer;
      });
    },
  },
};
</script>

<style>
/* .delimiter td {
  border-top: 1px solid red !important;
} */

#prefatture
  > div
  > div
  > div.v-data-table.v-data-table--fixed-header.v-data-table--has-top.v-data-table--has-bottom.theme--light
  > div.v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  font-weight: 900;
  font-size: 14px;
  color: black;
}
</style>