import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#161C2D',
                secondary: '#4EABDD',
                // accent: '#8c9eff'
                //  error: '#da0000'
            }
        }
    }
});
