<template>
  <v-container style="height: 100%" class="d-flex align-center justify-center">
    <v-row>
      <v-col cols="12">
        <h2>Recupera password</h2>
        <v-form
          ref="form"
          @submit.prevent="conferma()"
          class="size-l text-center"
          v-model="valid"
        >
          <v-text-field
            v-model="cdFornitore"
            label="Codice fornitore"
            required
            autocomplete="username"
            clearable
            :rules="[(v) => !!v || 'Campo obbligatorio']"
            maxlength="6"
            counter="6"
            @keypress="isInteger($event)"
          ></v-text-field>
          <v-text-field
            v-model="pIva"
            label="Partita IVA"
            required
            clearable
            maxlength="11"
            counter="11"
            @keypress="isInteger($event)"
            :rules="[
              (v) => !!v || 'Campo obbligatorio',
              (v) => validaPIva(v) || 'Il valore non valido',
            ]"
          ></v-text-field>
          <v-btn
            color="primary"
            class="mt-2"
            :disabled="!valid"
            type="submit"
            rounded
          >
            Conferma
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="500px" persistent>
      <v-alert outlined type="success" elevation="2" class="mb-0">
        Mail per il recupero della password inviata
        <v-btn class="align-self-end" @click="$router.push('/')" color="success" >Ok</v-btn>
      </v-alert>
    </v-dialog>
  </v-container>
</template>


<script>
// import router from "../router";

export default {
  name: "RecuperaPswd",
  data: () => ({ cdFornitore: "", pIva: "", dialog: false, valid: false }),
  methods: {
    isInteger(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    normalize(pi) {
      return pi.replace(/\s/g, "");
    },
    validaPIva(pi) {
      pi = this.normalize(pi);
      if (pi.length === 0) return false;
      else if (pi.length !== 11) return false;
      if (!/^[0-9]{11}$/.test(pi)) return false;
      var s = 0;
      for (var i = 0; i < 11; i++) {
        var n = pi.charCodeAt(i) - "0".charCodeAt(0);
        if ((i & 1) === 1) {
          n *= 2;
          if (n > 9) n -= 9;
        }
        s += n;
      }
      if (s % 10 !== 0) return false;
      return true;
    },
    conferma() {
      this.$store.state.loading = true;
      this.axios
        .post(`${this.$store.state.url_connessione}FoUtentis/ResetPassword`, {
          codice: this.cdFornitore,
          piva: this.pIva,
        })
        .then(() => {
          this.$store.state.loading = false;
          this.dialog = true;
        })
        .catch((error) => {
          this.$store.state.loading = false;
          if (error.response != undefined) {
            if (error.response.status == 404) {
              this.$root.Snackbar.show({
                text: error.response.data,
                type: "error",
              });
            }
            if (error.response.status == 401) {
              this.$root.$emit("logout");
              this.$root.Snackbar.show({
                text: error.response.data,
                type: "error",
              });
            }
          }
        });
    },
  },
};
</script>

<style>
.v-alert--outlined {
  background-color: white !important;
}

.v-alert__content {
  display: flex;
  flex-direction: column;
}
</style>