<template>
  <v-container id="trazionisti" style="height: 100%">
    <v-row>
      <v-col cols="12">
        <v-data-table
          :loading="!datiCaricati"
          loading-text="Loading..."
          :headers="headers"
          :items="diari"
          :items-per-page="-1"
          no-data-text="Nessun dato disponibile"
          fixed-header
          height="calc(100vh - 351px)"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          item-key="tdvdia"
          dense
          @current-items="getData"
          :disable-pagination="true"
        >
          <template v-slot:top>
            <v-card-title>
              Diari viaggio <v-spacer></v-spacer>
              <!-- <v-select
                v-if="tipoUtente == 'S' || tipoUtente == 'A'"
                v-model="codiceFo"
                :items="fornitori"
                label="Seleziona fornitore"
                @change="getDiari()"
                style="max-width: 180px"
                class="mr-4"
              ></v-select> -->
              <!-- <p class="mb-0 mr-4">{{ ragSocFor }}</p> -->
              <v-combobox
                v-if="tipoUtente == 'S' || tipoUtente == 'A'"
                v-model="codiceFo"
                :items="fornitori"
                label="Seleziona fornitore"
                @change="getDiari()"
                style="max-width: 450px"
                class="mr-4"
                :search-input.sync="search"
              >
              </v-combobox>
              <v-select
                v-if="tipoUtente == 'S' || tipoUtente == 'A'"
                v-model="mese"
                :items="mesi"
                label="Seleziona mese/anno"
                item-text="text"
                item-value="value"
                @change="getDiari()"
                style="max-width: 180px"
              ></v-select>
              <v-select
                v-if="tipoUtente != 'S' && tipoUtente != 'A'"
                v-model="mese"
                :items="mesi"
                label="Seleziona mese/anno"
                item-text="text"
                item-value="value"
                @change="getDiari()"
                style="max-width: 180px"
              ></v-select>
            </v-card-title>
            <v-card-subtitle style="width: 50%"
              ><b
                >Per eventuali discordanze inviare comunicazione entro e non
                oltre il settimo giorno del mese successivo al Sig. Fabio
                Ogliari – mail:
                <a href="mailto: fabio.ogliari@arcospedizioni.it"
                  >fabio.ogliari@arcospedizioni.it</a
                >
              </b></v-card-subtitle
            >
          </template>
          <template v-slot:[`item.colli`]="{ item }">
            {{ formatNumber(item.colli, 0) }}
          </template>

          <template v-slot:[`item.peso`]="{ item }">
            {{ formatNumber(item.peso, 1) }}
          </template>

          <template v-slot:[`item.volume`]="{ item }">
            {{ formatNumber(item.volume, 2) }}
          </template>

          <template v-slot:[`item.tdvttr`]="{ item }">
            {{ formatNumber(item.tdvttr, 2) }}
          </template>

          <template v-slot:[`item.tdvtsv`]="{ item }">
            {{ formatNumber(item.tdvtsv, 2) }}
          </template>
          <template v-slot:[`item.importoEts`]="{ item }">
            {{ formatNumber(item.importoEts, 2) }}
          </template>
          <template v-slot:[`item.totale_viaggio`]="{ item }">
            {{ formatNumber(item.totale_viaggio, 2) }}
          </template>

          <template v-slot:expanded-item="{ item }">
            <td :colspan="4" style="background-color: lightgrey">
              <h3 class="ma-0">Totale automezzo</h3>
              <!-- <p class="ma-0">Percentuale sconto</p>
              <p class="ma-0">Totale scontato</p> -->
            </td>
            <td :colspan="1" style="background-color: lightgrey">
              <h3>N. {{ sommaColli(item.automezzi) }}</h3>

              <!-- <br /><br /><br /> -->
            </td>
            <td :colspan="1" style="background-color: lightgrey">
              <h3>Kg. {{ sommaPeso(item.automezzi) }}</h3>

              <!-- <br /><br /><br /> -->
            </td>
            <td :colspan="1" style="background-color: lightgrey">
              <h3>m³ {{ sommaVolume(item.automezzi) }}</h3>

              <!-- <br /><br /><br /> -->
            </td>
            <td :colspan="1" style="background-color: lightgrey">
              <h3>Euro {{ sommaTrazione(item.automezzi) }}</h3>

              <!-- <br /><br /><br /> -->
            </td>
            <td :colspan="1" style="background-color: lightgrey">
              <h3>Euro {{ sommaServizi(item.automezzi) }}</h3>

              <!-- <br /><br /><br /> -->
            </td>
            <td :colspan="1" style="background-color: lightgrey">
              <h3>Euro {{ sommaEts(item.automezzi) }}</h3>

              <!-- <br /><br /><br /> -->
            </td>
            <td :colspan="2" style="background-color: lightgrey">
              <h3>Euro {{ sommaTotale(item.automezzi) }}</h3>
              <!-- <p class="ma-0">% {{ sconto == null ? 0.0 : sconto }}</p>
              <p class="ma-0" v-if="totaleScontato != null">
                Euro {{ formatNumber(totaleScontato, 2) }}
              </p> -->
              <!-- <br v-if="totaleScontato == null" /> -->
            </td>
          </template>

          <template v-slot:[`body.append`]="{}" v-if="datiCaricati">
            <tr style="height: 20px"></tr>
            <tr
              class="mt-4 last-row"
              style="background-color: #4eabdd; color: white"
            >
              <td :colspan="4"><h2 class="ma-0">Totali</h2></td>
              <td :colspan="1">
                <h2>N. {{ totaleColli(diari) }}</h2>
              </td>
              <td :colspan="1">
                <h2>Kg. {{ totalePeso(diari) }}</h2>
              </td>
              <td :colspan="1">
                <h2>m³ {{ totaleVolume(diari) }}</h2>
              </td>
              <td :colspan="1">
                <h2>Euro {{ totaleTrazione(diari) }}</h2>
              </td>
              <td :colspan="1">
                <h2>Euro {{ totaleServizi(diari) }}</h2>
              </td>
              <td :colspan="1">
                <h2>Euro {{ totaleEts(diari) }}</h2>
              </td>
              <td :colspan="2">
                <h2 class="ma-0">Euro {{ totalone() }}</h2>
              </td>
            </tr>
            <!-- <tr
              class="mt-4 last-row"
              style="background-color: #4eabdd; color: white"
            >
              <td :colspan="4"><h2 class="ma-0">Percentuale sconto</h2></td>
              <td :colspan="1"></td>
              <td :colspan="1"></td>
              <td :colspan="1"></td>
              <td :colspan="1"></td>
              <td :colspan="1"></td>
              <td :colspan="2">
                <h2 class="ma-0">% {{ sconto == null ? 0 : sconto }}</h2>
              </td>
            </tr>
            <tr
              class="mt-4 last-row"
              style="background-color: #4eabdd; color: white"
            >
              <td :colspan="4"><h2 class="ma-0">Totale scontato</h2></td>
              <td :colspan="1"></td>
              <td :colspan="1"></td>
              <td :colspan="1"></td>
              <td :colspan="1"></td>
              <td :colspan="1"></td>
              <td :colspan="2">
                <h2 class="ma-0" v-if="totaloneScontato != null">
                  Euro {{ formatNumber(totaloneScontato, 2) }}
                </h2>
              </td>
            </tr> -->
            <tr
              class="mt-4 last-row"
              style="background-color: #4eabdd; color: white"
            >
              <td :colspan="4"><h2 class="ma-0">Percentuale extra fuel</h2></td>
              <td :colspan="1"></td>
              <td :colspan="1"></td>
              <td :colspan="1"></td>
              <td :colspan="1"></td>
              <td :colspan="1"></td>
              <td :colspan="1"></td>
              <td :colspan="2">
                <h2 class="ma-0">% {{ fuel == null ? 0 : fuel }}</h2>
              </td>
            </tr>
            <tr
              class="mt-4 last-row"
              style="background-color: #4eabdd; color: white"
              v-if="
                totaleMaggiorato != null ||
                totEts == 0 ||
                totEts == null ||
                totEts == undefined
              "
            >
              <td :colspan="4">
                <h2
                  class="ma-0"
                  v-if="
                    totEts != 0 &&
                    totEts != null &&
                    totEts != undefined &&
                    totaleMaggiorato != null
                  "
                >
                  Totale (servizi e fuel)
                </h2>
                <h2
                  class="ma-0"
                  v-if="totEts == 0 || totEts == null || totEts == undefined"
                >
                  Totale da fatturare (IVA esclusa)
                </h2>
              </td>
              <td :colspan="1"></td>
              <td :colspan="1"></td>
              <td :colspan="1"></td>
              <td :colspan="1"></td>
              <td :colspan="1"></td>
              <td :colspan="1"></td>
              <td :colspan="2">
                <h2 class="ma-0" v-if="totaleMaggiorato != null">
                  Euro {{ formatNumber(totaleMaggiorato, 2) }}
                </h2>
                <h2 class="ma-0" v-if="totaleMaggiorato == null">
                  Euro {{ totalone() }}
                </h2>
              </td>
            </tr>
            <tr
              class="mt-4 last-row"
              style="background-color: #4eabdd; color: white"
              v-if="totEts != 0 && totEts != null && totEts != undefined"
            >
              <td :colspan="4">
                <h2 class="ma-0">Totale da fatturare con ETS (IVA esclusa)</h2>
              </td>
              <td :colspan="1"></td>
              <td :colspan="1"></td>
              <td :colspan="1"></td>
              <td :colspan="1"></td>
              <td :colspan="1"></td>
              <td :colspan="1"></td>
              <td :colspan="2">
                <h2 class="ma-0">Euro {{ totaleConEts() }}</h2>
              </td>
            </tr>
            <!-- <tr class="mt-4" style="background-color: #4eabdd; color: white">
              <td :colspan="4">
                <h2 class="ma-0">Totali</h2>
                <h2 class="ma-0">Percentuale sconto</h2>
                <h2 class="ma-0">Totale scontato</h2>
                <h2 class="ma-0">Percentuale extra fuel</h2>
                <h2 class="ma-0">Totale maggiorato</h2>
              </td>
              <td :colspan="1">
                <h2>N. {{ totaleColli() }}</h2>
         
              </td>
              <td :colspan="1">
                <h2>Kg. {{ totalePeso() }}</h2>
                <br /><br /><br /><br /><br /><br />
              </td>
              <td :colspan="1">
                <h2>m³ {{ totaleVolume() }}</h2>
                <br /><br /><br /><br /><br /><br />
              </td>
              <td :colspan="1">
                <h2>Euro {{ totaleTrazione() }}</h2>
                <br /><br /><br /><br /><br /><br />
              </td>
              <td :colspan="1">
                <h2>Euro {{ totaleServizi() }}</h2>
                <br /><br /><br /><br /><br /><br />
              </td>
              <td :colspan="2">
                <h2 class="ma-0">Euro {{ totalone() }}</h2>
                <h2 class="ma-0">% {{ sconto == null ? 0 : sconto }}</h2>
                <h2 class="ma-0" v-if="totaloneScontato != null">
                  Euro {{ formatNumber(totaloneScontato, 2) }}
                </h2>
                <br v-if="totaloneScontato == null" />
                <h2 class="ma-0">% {{ fuel == null ? 0 : fuel }}</h2>
                <h2 class="ma-0" v-if="totaleMaggiorato != null">
                  Euro {{ formatNumber(totaleMaggiorato, 2) }}
                </h2>
                <br v-if="totaleMaggiorato == null" />
                <br />
              </td>
            </tr> -->
          </template>

          <template v-slot:[`footer.prepend`]>
            <v-btn
              @click="downloadCSV()"
              text
              class="ml-4 my-3"
              color="grey darken-3"
            >
              Esporta
              <v-icon right> mdi-download </v-icon>
            </v-btn>
            <v-btn
              @click="downloadCSVTutti()"
              text
              class="ml-4 my-3"
              color="grey darken-3"
              v-if="tipoUtente == 'S' || tipoUtente == 'A'"
            >
              Esporta tutti
              <v-icon right> mdi-download </v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <!-- EXPORT DIALOG -->
        <v-dialog v-model="dialogCSV" max-width="500px">
          <v-card>
            <v-card-title>Download CSV</v-card-title>
            <v-card-text>
              <div class="mt-4">
                <p style="display: inline">File .csv pronto!</p>
                <a
                  :download="'Diari_Viaggio-' + dateNow + '.csv'"
                  :href="fileCSV"
                  style="text-decoration: none; display: inline"
                  class="ml-4"
                >
                  <v-btn @click="dialogCSV = false" color="primary">
                    Download
                    <v-icon right> mdi-download </v-icon>
                  </v-btn>
                </a>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialogCSV = false"
                >Chiudi</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- EXPORT TUTTI DIALOG -->
        <v-dialog v-model="dialogCSVTutti" max-width="500px" persistent>
          <v-card>
            <v-card-title>Download CSV</v-card-title>
            <v-card-text>
              <div class="mt-4">
                <p
                  style="display: inline"
                  v-if="datiExportCaricati && !exportError"
                >
                  File .csv pronto!
                </p>
                <p
                  style="display: inline"
                  v-if="!datiExportCaricati && !exportError"
                >
                  Elaborazione dei dati
                </p>
                <p
                  style="display: inline"
                  v-if="datiExportCaricati && exportError"
                >
                  Non è stato possibile recuperare i dati. Riprovare più tardi.
                </p>
                <a
                  :download="'Diari_Viaggio_Tutti-' + dateNow + '.csv'"
                  :href="
                    datiExportCaricati ? fileCSVTutti : 'javascript:void(0)'
                  "
                  style="text-decoration: none; display: inline"
                  class="ml-4"
                  v-if="!exportError"
                >
                  <v-btn
                    @click="dialogCSVTutti = false"
                    color="primary"
                    :loading="!datiExportCaricati"
                  >
                    Download
                    <v-icon right> mdi-download </v-icon>
                  </v-btn>
                </a>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="closeDialogTutti()"
                v-if="datiExportCaricati"
                >Chiudi</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import router from "../router";

export default {
  name: "Trazionisti",
  data: () => ({
    diari: [],
    fuel: null,
    // sconto: null,
    buttonLoading: false,
    headers: [
      {
        text: "N. diario viaggio",
        align: "start",
        value: "tdvdia",
        sortable: false,
      },
      {
        text: "Data",
        value: "tdvdtv",

        sortable: false,
      },
      {
        text: "Descrizione",
        value: "descrizione",
        sortable: false,
      },
      {
        text: "Automezzi",
        value: "automezzi",
        sortable: false,
      },
      {
        text: "Colli",
        value: "colli",
        sortable: false,
      },
      {
        text: "Peso",
        value: "peso",
        sortable: false,
      },
      {
        text: "Volume",
        value: "volume",
        sortable: false,
      },
      {
        text: "Trazione",
        value: "tdvttr",
        sortable: false,
      },
      {
        text: "Servizi",
        value: "tdvtsv",
        sortable: false,
      },
      {
        text: "ETS",
        value: "importoEts",
        sortable: false,
      },
      {
        text: "Totale Viaggio",
        value: "totale_viaggio",
        sortable: false,
      },
      { text: "", value: "data-table-expand", sortable: false },
    ],
    mesi: [],
    mese: "",
    expanded: [],
    singleExpand: false,
    // totaleScontato: null,
    // totaloneScontato: null,
    totaleMaggiorato: null,
    indexes: [],
    datiCaricati: true,
    fornitori: [],
    codiceFo: null,
    tipoUtente: "",
    search: "",
    ragSocFor: "",
    dialogCSV: false,
    currentItems: [],
    fileCSV: null,
    dateNow: "",
    dialogCSVTutti: false,
    fileCSVTutti: null,
    diariExport: [],
    datiExportCaricati: true,
    exportError: false,
    totEts: 0,
  }),
  mounted() {
    this.$store.state.loading = true;
    // localStorage.setItem("tipologia", "A");
    this.tipoUtente = localStorage.getItem("tipologia");

    var currentMonth = new Date().getMonth();

    var months = [
      {
        text: "Gennaio",
        value: 0,
      },
      {
        text: "Febbraio",
        value: 1,
      },
      {
        text: "Marzo",
        value: 2,
      },
      {
        text: "Aprile",
        value: 3,
      },
      {
        text: "Maggio",
        value: 4,
      },
      {
        text: "Giugno",
        value: 5,
      },
      {
        text: "Luglio",
        value: 6,
      },
      {
        text: "Agosto",
        value: 7,
      },
      {
        text: "Settembre",
        value: 8,
      },
      {
        text: "Ottobre",
        value: 9,
      },
      {
        text: "Novembre",
        value: 10,
      },
      {
        text: "Dicembre",
        value: 11,
      },
    ];
    var currentMonthName = "";
    var lastYear = [];
    var thisYear = [];

    months.forEach((el) => {
      var text = "";
      if (el.value < currentMonth && el.value > currentMonth - 6) {
        text = `${el.text} ${new Date().getFullYear()}`;
        thisYear.push({
          text: text,
          value: `${el.value}-${new Date().getFullYear()}`,
        });
      }
      if (el.value > currentMonth && el.value > currentMonth + 6) {
        text = `${el.text} ${new Date().getFullYear() - 1}`;
        lastYear.push({
          text: text,
          value: `${el.value}-${new Date().getFullYear() - 1}`,
        });
      }
      if (el.value == currentMonth) {
        currentMonthName = el.text;
      }
    });

    // lastYear.push({
    //   text: `${currentMonthName} ${new Date().getFullYear() - 1}`,
    //   value: `${currentMonth}-${new Date().getFullYear() - 1}`,
    // });
    thisYear.push({
      text: `${currentMonthName} ${new Date().getFullYear()}`,
      value: `${currentMonth}-${new Date().getFullYear()}`,
    });

    lastYear = lastYear.sort(
      (a, b) => a.value.split("-")[0] - b.value.split("-")[0]
    );
    thisYear = thisYear.sort(
      (a, b) => a.value.split("-")[0] - b.value.split("-")[0]
    );
    this.mesi = [...lastYear, ...thisYear];
    this.mese = `${currentMonth}-${new Date().getFullYear()}`;

    if (
      localStorage.getItem("tipologia") != "A" &&
      localStorage.getItem("tipologia") != "S"
    ) {
      this.getDiari();
    } else {
      this.fornitori = JSON.parse(localStorage.getItem("fornitoriTrazionisti"));
      // this.fornitori = ["123", "543"];
      this.$store.state.loading = false;
    }
  },
  methods: {
    getDiari() {
      this.diari = [];
      this.expanded = [];
      this.datiCaricati = false;
      this.totaleMaggiorato = null;
      // this.totaloneScontato = null;
      // this.totaleScontato = null;
      this.ragSocFor = "";
      // this.sconto = null;
      this.fuel = null;
      var month = parseInt(this.mese.split("-")[0]) + 1;

      this.axios
        .post(`${this.$store.state.url_connessione}Ttrdv10f`, {
          mese: month.toString().padStart(2, "0"),
          anno: this.mese.split("-")[1],
          codice: this.codiceFo == null ? "" : this.codiceFo.value,
        })
        .then((response) => {
          this.$store.state.loading = false;
          if (response.data.diariViaggio != null) {
            this.diari = response.data.diariViaggio;
            this.fuel = response.data.extraFuel.teffue;
            // this.sconto = response.data.sconto.tspsco;

            this.diari.map((el) => {
              el.tdvdia = `${el.tdvdia.substr(0, 4)}-${el.tdvdia.substr(
                4,
                3
              )}-${el.tdvdia.substr(7, 6)}`;

              var anno = el.tdvdtv.substr(0, 4);
              var mese = el.tdvdtv.substr(4, 2);
              var giorno = el.tdvdtv.substr(6, 2);
              el.tdvdtv = `${giorno}/${mese}/${anno}`;

              el.tdvttr = Number(el.tdvttr) / 100;
              el.tdvtsv = Number(el.tdvtsv) / 100;
              el["automezzi"] = `${el.tdvfor}-${el.tdvcau}`;
              el["totale_viaggio"] = Number(el.tdvttr) + Number(el.tdvtsv);
            });

            // console.log(this.diari);
            this.diari.sort(
              (a, b) =>
                a.automezzi.localeCompare(b.automezzi) ||
                a.tdvdtv.split("/").reverse().join("") -
                  b.tdvdtv.split("/").reverse().join("")
            );

            var automezzi = {};
            this.indexes = [];

            this.diari.forEach((el, i) => {
              if (
                Object.prototype.hasOwnProperty.call(automezzi, el.automezzi)
              ) {
                automezzi[el.automezzi].push(i);
              } else {
                automezzi[el.automezzi] = [i];
              }
            });

            var values = Object.values(automezzi);
            values.forEach((el) => {
              this.indexes.push(Math.max(...el));
            });

            this.indexes.forEach((el) => {
              this.expanded.push(this.diari[el]);
            });
          }

          if (
            (localStorage.getItem("tipologia") == "A" ||
              localStorage.getItem("tipologia") == "S") &&
            response.data.ragioneSociale != null &&
            response.data.ragioneSociale != undefined &&
            response.data.ragioneSociale != ""
          ) {
            this.ragSocFor = response.data.ragioneSociale;
          }
          this.datiCaricati = true;
        })
        .catch((error) => {
          this.datiCaricati = true;
          this.$store.state.loading = false;
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout");
              this.$root.Snackbar.show({
                text: error.response.data,
                type: "error",
              });
            }
          }
        });
    },
    getTuttiDiari() {
      this.datiExportCaricati = false;
      this.diariExport = [];
      var month = parseInt(this.mese.split("-")[0]) + 1;

      return new Promise((resolve, reject) => {
        this.axios
          .post(`${this.$store.state.url_connessione}Ttrdv10f/esportaTutti`, {
            mese: month.toString().padStart(2, "0"),
            anno: this.mese.split("-")[1],
            codice: "",
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    formatNumber(val, dec) {
      return val.toLocaleString("it-IT", {
        minimumFractionDigits: dec,
        maximumFractionDigits: dec,
      });
    },
    sommaColli(automezzo) {
      var somma = 0;
      this.diari.forEach((el) => {
        if (el.automezzi == automezzo) {
          somma += el.colli;
        }
      });
      return somma.toLocaleString("it-IT");
    },
    sommaPeso(automezzo) {
      var somma = 0;
      this.diari.forEach((el) => {
        if (el.automezzi == automezzo) {
          somma += el.peso;
        }
      });
      return somma.toLocaleString("it-IT", {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      });
    },
    sommaVolume(automezzo) {
      var somma = 0;
      this.diari.forEach((el) => {
        if (el.automezzi == automezzo) {
          somma += el.volume;
        }
      });
      return somma.toLocaleString("it-IT", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    sommaTrazione(automezzo) {
      var somma = 0;
      this.diari.forEach((el) => {
        if (el.automezzi == automezzo) {
          somma += Number(el.tdvttr);
        }
      });
      return somma.toLocaleString("it-IT", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    sommaServizi(automezzo) {
      var somma = 0;
      this.diari.forEach((el) => {
        if (el.automezzi == automezzo) {
          somma += Number(el.tdvtsv);
        }
      });
      return somma.toLocaleString("it-IT", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    sommaEts(automezzo) {
      var somma = 0;
      this.diari.forEach((el) => {
        if (el.automezzi == automezzo) {
          somma += Number(el.importoEts);
        }
      });
      return somma.toLocaleString("it-IT", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    sommaTotale(automezzo) {
      var somma = 0;
      this.diari.forEach((el) => {
        if (el.automezzi == automezzo) {
          somma += Number(el.totale_viaggio);
        }
      });

      // if (this.sconto != null) {
      //   this.totaleScontato = somma.toFixed(2) * (1 - this.sconto / 100);
      // }
      return somma.toLocaleString("it-IT", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    totaleColli(diari) {
      var somma = 0;
      diari.forEach((el) => {
        somma += el.colli;
      });
      return somma.toLocaleString("it-IT");
    },
    totalePeso(diari) {
      var somma = 0;
      diari.forEach((el) => {
        somma += el.peso;
      });

      return somma.toLocaleString("it-IT", {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      });
    },
    totaleVolume(diari) {
      var somma = 0;
      diari.forEach((el) => {
        somma += el.volume;
      });
      return somma.toLocaleString("it-IT", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    totaleTrazione(diari) {
      var somma = 0;
      diari.forEach((el) => {
        somma += Number(el.tdvttr);
      });
      return somma.toLocaleString("it-IT", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    totaleServizi(diari) {
      var somma = 0;
      diari.forEach((el) => {
        somma += Number(el.tdvtsv);
      });
      return somma.toLocaleString("it-IT", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    totaleEts(diari) {
      var somma = 0;
      diari.forEach((el) => {
        somma += Number(el.importoEts);
      });
      this.totEts = somma;

      return this.totEts.toLocaleString("it-IT", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    totalone() {
      var somma = 0;
      // var sconto = "";
      var fuel = "";
      this.diari.forEach((el) => {
        somma += Number(el.totale_viaggio);
      });

      // if (this.sconto != null) {
      //   sconto = this.sconto.replace(",", ".");
      //   this.totaloneScontato = somma * (1 - Number(sconto) / 100);
      //   if (this.fuel != null) {
      //     fuel = this.fuel.replace(",", ".");
      //     this.totaleMaggiorato =
      //       this.totaloneScontato +
      //       this.totaloneScontato * (Number(fuel) / 100);
      //   }
      // }
      
      if (this.fuel != null) {
        fuel = this.fuel.replace(",", ".");
        this.totaleMaggiorato = somma + somma * (Number(fuel) / 100);
      }

      return somma.toLocaleString("it-IT", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    totaleConEts() {
      var somma = 0;
      this.diari.forEach((el) => {
        somma += Number(el.importoEts);
      });
      if (this.totaleMaggiorato != null) {
        return (this.totaleMaggiorato + somma).toLocaleString("it-IT", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else {
        var somma2 = 0;
        this.diari.forEach((el) => {
          somma2 += Number(el.totale_viaggio);
        });
        return (somma2 + somma).toLocaleString("it-IT", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    },
    getData(e) {
      this.currentItems = e;
    },
    downloadCSV() {
      this.dialogCSV = true;
      var csv = "";
      var itemsBuffer = this.itemsPerPage;
      this.itemsPerPage = -1;
      this.$nextTick(() => {
        csv += `"N. diario viaggio";"Data";"Descrizione";"Automezzi";"Colli";"Peso";"Volume";"Trazione";"Servizi";"ETS";"Totale viaggio"\n`;
        this.currentItems.forEach((row, i) => {
          csv += `"${row.tdvdia}";"${row.tdvdtv}";"${row.descrizione}";"${
            row.automezzi
          }";"${this.formatNumber(row.colli, 0)}";"${this.formatNumber(
            row.peso,
            1
          )}";"${this.formatNumber(row.volume, 2)}";"${this.formatNumber(
            row.tdvttr,
            2
          )}";"${this.formatNumber(row.tdvtsv, 2)}";"${this.formatNumber(
            row.importoEts,
            2
          )}";"${this.formatNumber(row.totale_viaggio, 2)}";\n`;
          if (this.indexes.includes(i)) {
            csv += `"Totale automezzo";"";"";"";"N. ${this.sommaColli(
              row.automezzi
            )}";"Kg. ${this.sommaPeso(row.automezzi)}";"m³ ${this.sommaVolume(
              row.automezzi
            )}";"Euro ${this.sommaTrazione(
              row.automezzi
            )}";"Euro ${this.sommaServizi(
              row.automezzi
            )}";"Euro ${this.sommaEts(row.automezzi)}";"Euro ${this.sommaTotale(
              row.automezzi
            )}";\n`;
          }
        });
        csv += `\n"Totali";"";"";"";"N. ${this.totaleColli(
          this.diari
        )}";"Kg. ${this.totalePeso(this.diari)}";"m³ ${this.totaleVolume(
          this.diari
        )}";"Euro ${this.totaleTrazione(
          this.diari
        )}";"Euro ${this.totaleServizi(this.diari)}";"Euro ${this.totaleEts(
          this.diari
        )}";"Euro ${this.totalone()}";\n`;
        csv += `"Percentuale extra fuel";"";"";"";"";"";"";"";"";"";"% ${
          this.fuel == null ? 0 : this.fuel
        }";\n`;

        if (
          this.totEts != 0 &&
          this.totEts != null &&
          this.totEts != undefined
        ) {
          if (this.totaleMaggiorato != null) {
            csv += `"Totale (servizi e fuel)";"";"";"";"";"";"";"";"";"";"Euro ${this.formatNumber(
              this.totaleMaggiorato,
              2
            )}";\n`;
          }
          csv += `"Totale da fatturare con ETS (IVA esclusa)";"";"";"";"";"";"";"";"";"";"Euro ${this.totaleConEts()}";\n`;
        } else {
          csv += `"Totale da fatturare (IVA esclusa)";"";"";"";"";"";"";"";"";"";"Euro ${
            this.totaleMaggiorato != null
              ? this.formatNumber(this.totaleMaggiorato, 2)
              : this.totalone()
          }";\n`;
        }

        var universalBOM = "\uFEFF";
        var csvData = new Blob([universalBOM + csv], {
          type: "text/csv;charset=utf-8",
        });
        this.fileCSV = URL.createObjectURL(csvData);
        var d = new Date();
        this.dateNow =
          d.getFullYear() +
          "" +
          (d.getMonth() + 1) +
          "" +
          d.getDate() +
          "" +
          d.getHours() +
          "" +
          d.getMinutes() +
          "" +
          d.getSeconds();
        this.itemsPerPage = itemsBuffer;
      });
    },
    downloadCSVTutti() {
      this.exportError = false;
      this.dialogCSVTutti = true;
      this.getTuttiDiari()
        .then((response) => {
          this.diariExport = [...response.data];

          //Formattazione dati
          if (this.diariExport.length > 0) {
            this.diariExport.forEach((fornitore) => {
              if (
                fornitore.diariViaggio != null &&
                fornitore.diariViaggio.length > 0
              ) {
                fornitore.diariViaggio.map((el) => {
                  el.tdvdia = `${el.tdvdia.substr(0, 4)}-${el.tdvdia.substr(
                    4,
                    3
                  )}-${el.tdvdia.substr(7, 6)}`;

                  var anno = el.tdvdtv.substr(0, 4);
                  var mese = el.tdvdtv.substr(4, 2);
                  var giorno = el.tdvdtv.substr(6, 2);
                  el.tdvdtv = `${giorno}/${mese}/${anno}`;

                  el.tdvttr = Number(el.tdvttr) / 100;
                  el.tdvtsv = Number(el.tdvtsv) / 100;
                  el["automezzi"] = `${el.tdvfor}-${el.tdvcau}`;
                  el["totale_viaggio"] = Number(el.tdvttr) + Number(el.tdvtsv);
                });

                fornitore.diariViaggio.sort(
                  (a, b) =>
                    a.automezzi.localeCompare(b.automezzi) ||
                    a.tdvdtv.split("/").reverse().join("") -
                      b.tdvdtv.split("/").reverse().join("")
                );
              }
            });

            //Creazione file csv
            var csv = "";
            this.$nextTick(() => {
              csv += `"Totali";"Cod. fornitore";"Ragione sociale";"N. diario viaggio";"Data";"Descrizione";"Automezzi";"Colli";"Peso";"Volume";"Trazione";"Servizi";"ETS";"Totale viaggio"\n`;
              this.diariExport.forEach((fornitore) => {
                //Calcolo totale e totale maggiorato
                var somma = 0;
                var fuel = "";
                var totale = "";
                var totaleMagg = null;
                var totEts = 0;
                var totaleConEts = "";
                fornitore.diariViaggio.forEach((el) => {
                  somma += Number(el.totale_viaggio);
                });

                fornitore.diariViaggio.forEach((el) => {
                  totEts += Number(el.importoEts);
                });

                if (fornitore.extraFuel.teffue != null) {
                  fuel = fornitore.extraFuel.teffue.replace(",", ".");
                  totaleMagg = somma + somma * (Number(fuel) / 100);
                  totaleConEts = (totEts + totaleMagg).toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                } else {
                  totaleConEts = (totEts + somma).toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                }

                totale = somma.toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });

                //Creo i record
                fornitore.diariViaggio.forEach((row) => {
                  csv += `"";"${fornitore.codiceFornitore}";"${
                    fornitore.ragioneSociale
                  }";"${row.tdvdia}";"${row.tdvdtv}";"${row.descrizione}";"${
                    row.automezzi
                  }";"${this.formatNumber(row.colli, 0)}";"${this.formatNumber(
                    row.peso,
                    1
                  )}";"${this.formatNumber(
                    row.volume,
                    2
                  )}";"${this.formatNumber(
                    row.tdvttr,
                    2
                  )}";"${this.formatNumber(
                    row.tdvtsv,
                    2
                  )}";"${this.formatNumber(
                    row.importoEts,
                    2
                  )}";"${this.formatNumber(row.totale_viaggio, 2)}";\n`;
                });
                csv += `"Totali";"${fornitore.codiceFornitore}";"${
                  fornitore.ragioneSociale
                }";"";"";"";"";"N. ${this.totaleColli(
                  fornitore.diariViaggio
                )}";"Kg. ${this.totalePeso(
                  fornitore.diariViaggio
                )}";"m³ ${this.totaleVolume(
                  fornitore.diariViaggio
                )}";"${this.totaleTrazione(
                  fornitore.diariViaggio
                )}";"${this.totaleServizi(
                  fornitore.diariViaggio
                )}";"${this.totaleEts(fornitore.diariViaggio)}";"${totale}";\n`;
                csv += `"Percentuale extra fuel";"${
                  fornitore.codiceFornitore
                }";"${
                  fornitore.ragioneSociale
                }";"";"";"";"";"";"";"";"";"";"";"% ${
                  fornitore.extraFuel.teffue == null
                    ? 0
                    : fornitore.extraFuel.teffue
                }";\n`;

                if (totEts != 0 && totEts != null && totEts != undefined) {
                  if (totaleMagg != null) {
                    csv += `"Totale (servizi e fuel)";"${
                      fornitore.codiceFornitore
                    }";"${
                      fornitore.ragioneSociale
                    }";"";"";"";"";"";"";"";"";"";"";"${
                      totaleMagg != null
                        ? this.formatNumber(totaleMagg, 2)
                        : totale
                    }";\n`;
                  }
                  csv += `"Totale da fatturare con ETS (IVA esclusa)";"${fornitore.codiceFornitore}";"${fornitore.ragioneSociale}";"";"";"";"";"";"";"";"";"";"";"${totaleConEts}";\n`;
                } else {
                  csv += `"Totale da fatturare (IVA esclusa)";"${
                    fornitore.codiceFornitore
                  }";"${
                    fornitore.ragioneSociale
                  }";"";"";"";"";"";"";"";"";"";"";"${
                    totaleMagg != null
                      ? this.formatNumber(totaleMagg, 2)
                      : totale
                  }";\n`;
                }
              });

              var universalBOM = "\uFEFF";
              var csvData = new Blob([universalBOM + csv], {
                type: "text/csv;charset=utf-8",
              });
              this.fileCSVTutti = URL.createObjectURL(csvData);
              var d = new Date();
              this.dateNow =
                d.getFullYear() +
                "" +
                (d.getMonth() + 1) +
                "" +
                d.getDate() +
                "" +
                d.getHours() +
                "" +
                d.getMinutes() +
                "" +
                d.getSeconds();
            });
          }
          this.datiExportCaricati = true;
        })
        .catch((error) => {
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout");
              this.$root.Snackbar.show({
                text: error.response.data,
                type: "error",
              });
            } else {
              this.exportError = true;
            }
          }
          this.datiExportCaricati = true;
        });
    },
    closeDialogTutti() {
      this.dialogCSVTutti = false;
      this.exportError = false;
      this.datiExportCaricati = true;
    },
  },
};
</script>

<style>
.last-row td {
  border: none !important;
}

#trazionisti
  > div
  > div
  > div.v-data-table.v-data-table--dense.v-data-table--fixed-header.v-data-table--has-top.v-data-table--has-bottom.theme--light
  > div.v-data-footer
  > div.v-data-footer__select {
  display: none;
}

#trazionisti
  > div
  > div
  > div.v-data-table.v-data-table--dense.v-data-table--fixed-header.v-data-table--has-top.v-data-table--has-bottom.theme--light
  > div.v-data-footer
  > div.v-data-footer__pagination {
  display: none;
}

#trazionisti
  > div
  > div
  > div.v-data-table.v-data-table--dense.v-data-table--fixed-header.v-data-table--has-top.v-data-table--has-bottom.theme--light
  > div.v-data-footer
  > div.v-data-footer__icons-before {
  display: none;
}

#trazionisti
  > div
  > div
  > div.v-data-table.v-data-table--dense.v-data-table--fixed-header.v-data-table--has-top.v-data-table--has-bottom.theme--light
  > div.v-data-footer
  > div.v-data-footer__icons-after {
  display: none;
}
</style>