<template>
  <!-- <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    tile
    color="red accent-2"
    width="100vw"
    centered
  >
    {{ text }}
  </v-snackbar> -->
  <v-dialog v-model="snackbar" max-width="500px" color="red">
    <v-alert outlined :type="type" elevation="2" class="mb-0" text>
      {{ text }}
    </v-alert>
  </v-dialog>
</template>

<script>
export default {
  name: "Snackbar",
  data() {
    return {
      snackbar: false,
      text: "",
      timeout: 3000,
      type: ""
    };
  },
  methods: {
    show(data) {
      this.text = data.text;
      this.timeout = data.timeout || 3000;
      this.snackbar = true;
      this.type = data.type
    },
  },
};
</script>

<style>
.v-snack__content {
  text-align: center !important;
}
</style>