
<template>
  <v-app id="fornitori">
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Arco Logo"
          class="shrink mr-2"
          contain
          src="./assets/LogoArco.png"
          transition="scale-transition"
          width="180"
        />
      </div>
      <v-toolbar-title
        >Portale fornitori {{ $store.state.fornitore }}</v-toolbar-title
      >
    </v-app-bar>

    <v-navigation-drawer
      v-if="
        $route.path != '/' &&
        $route.path != '/recupera-password' &&
        $route.name != 'NuovaPswd' &&
        $route.name != 'PageError'
      "
      permanent
      absolute
      style="height: calc(100vh - 64px); margin-top: 64px"
      color="primary"
      dark
    >
      <v-list>
        <v-list-item v-if="$store.state.tipologia == 'S'" link to="/utenti">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Utenti</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="!($store.state.tipologia == 'T')"
          link
          to="/prefatture"
        >
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Prefatture</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="!($store.state.tipologia == 'P')"
          link
          to="/trazionisti"
        >
          <v-list-item-icon>
            <v-icon>mdi-truck</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Trazionisti</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-sheet
      :style="
        $route.path != '/' &&
        $route.path != '/recupera-password' &&
        $route.name != 'NuovaPswd' &&
        $route.name != 'PageError'
          ? 'padding: 64px 0 0 256px; height: calc(100vh - 54px)'
          : 'padding-top: 64px; height: calc(100vh - 54px)'
      "
    >
      <router-view v-if="checkHeader" @logout="logout"> </router-view>
      <Loading v-if="$store.state.loading" />
      <Snackbar ref="snackbar" />
    </v-sheet>

    <v-bottom-navigation
      fixed
      background-color="primary"
      dark
      class="d-flex justify-start"
    >
      <span class="d-flex align-center ml-4"
        >&copy; 2022 - Arco Spedizioni SpA</span
      >
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import router from "./router";
import Loading from "./components/Loading";
import Snackbar from "./components/Snackbar.vue";

export default {
  components: {
    Loading,
    Snackbar,
  },
  data: () => ({
    fornitore: "",
    checkHeader: false,
    refreshStarted: false,
  }),
  created() {
    this.checkLogin();
  },
  mounted() {
    this.$root.Snackbar = this.$refs.snackbar;
    this.$root.$once("logout", this.logout);
    if (
      localStorage.getItem("fornitore") != null &&
      localStorage.getItem("fornitore") != undefined &&
      localStorage.getItem("fornitore") != ""
    ) {
      this.$store.state.fornitore = localStorage.getItem("fornitore");
    }
    if (
      localStorage.getItem("tipologia") != null &&
      localStorage.getItem("tipologia") != undefined &&
      localStorage.getItem("tipologia") != ""
    ) {
      this.$store.state.tipologia = localStorage.getItem("tipologia");
    }
    if (
      localStorage.getItem("jwt") != null &&
      localStorage.getItem("jwt") != undefined &&
      localStorage.getItem("jwt") != ""
    ) {
      this.axios.defaults.headers.common["authorization"] =
        localStorage.getItem("jwt");
    }
  },
  methods: {
    checkLogin() {
      this.checkHeader = false;
      if (
        this.axios.defaults.headers.common["authorization"] != null &&
        this.axios.defaults.headers.common["authorization"] != undefined &&
        this.axios.defaults.headers.common["authorization"] != ""
      ) {
        if (!this.refreshStarted) {
          this.updateToken();
        }
      } else if (
        localStorage.getItem("jwt") != null &&
        localStorage.getItem("jwt") != undefined &&
        localStorage.getItem("jwt") != ""
      ) {
        if (!this.refreshStarted) {
          // this.axios.defaults.headers.common["authorization"] =
          //   localStorage.getItem("jwt");
          this.updateToken();
        }
      } else {
        this.checkHeader = true;
        this.$store.commit("destroyToken");
        if (this.$route.path != "/") {
          router.push("/");
        }
      }
    },
    updateToken() {
      this.checkHeader = false;
      if (
        this.$store.state.url_connessione != null &&
        this.$store.state.url_connessione != undefined
      ) {
        if (
          (this.axios.defaults.headers.common["authorization"] == "" ||
            this.axios.defaults.headers.common["authorization"] == undefined ||
            this.axios.defaults.headers.common["authorization"] == null) &&
          localStorage.getItem("jwt") != null &&
          localStorage.getItem("jwt") != undefined &&
          localStorage.getItem("jwt") != ""
        ) {
          this.axios.defaults.headers.common["authorization"] =
            localStorage.getItem("jwt");
        }

        if (
          localStorage.getItem("jwt") == null ||
          localStorage.getItem("jwt") == undefined ||
          localStorage.getItem("jwt") == ""
        ) {
          this.axios.defaults.headers.common["authorization"] = "";
        }

        this.refreshStarted = true;
        this.axios
          .post(`${this.$store.state.url_connessione}FoUtentis/Token`)
          .then((response) => {
            this.axios.defaults.headers.common["authorization"] = response.data;
            localStorage.setItem("jwt", response.data);
            this.refreshStarted = false;
            this.checkHeader = true;

            if (this.$route.path == "/") {
              if (localStorage.getItem("tipologia") == "P") {
                router.push("/prefatture");
              } else if (localStorage.getItem("tipologia") == "T") {
                router.push("/trazionisti");
              } else if (
                localStorage.getItem("tipologia") == "A" ||
                localStorage.getItem("tipologia") == "S"
              ) {
                router.push("/home");
              }
            }
          })
          .catch((error) => {
            this.refreshStarted = false;
            this.checkHeader = true;
            if (error.response != undefined) {
              if (error.response.status != 200) {
                this.axios.defaults.headers.common["authorization"] = "";
                this.$store.commit("destroyToken");
                if (this.$route.path != "/") {
                  router.push("/");
                }
              }
            }
          });
      }
    },
    logout() {
      this.axios.defaults.headers.common["authorization"] = "";
      this.$store.commit("destroyToken");
      router.push("/");
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title;
        if (this.$route.path == "/") {
          this.checkLogin();
        }

        if (
          (this.$route.name == "Utenti" &&
            localStorage.getItem("tipologia") != "S") ||
          (this.$route.name == "Trazionisti" &&
            localStorage.getItem("tipologia") == "P") ||
          (this.$route.name == "Prefatture" &&
            localStorage.getItem("tipologia") == "T")
        ) {
          this.logout();
        }

        if (
          !this.refreshStarted &&
          this.$route.path != "/" &&
          this.$route.path != "/recupera-password" &&
          this.$route.name != "NuovaPswd"
        ) {
          this.updateToken();
        }
      },
    },
  },
};
</script>