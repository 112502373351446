var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"height":"100%"},attrs:{"id":"utenti"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.utenti,"items-per-page":_vm.itemsPerPage,"sort-by":'identificativo',"sort-desc":false,"search":_vm.search,"custom-filter":_vm.filterAll,"multi-sort":"","no-data-text":"Nessun dato disponibile","no-results-text":"Nessun risultato","fixed-header":"","height":"calc(100vh - 339px)","footer-props":{
          'items-per-page-options': [15, 25, 50, -1],
          'items-per-page-text': 'Elementi per pagina',
          'items-per-page-all-text': 'Tutti',
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card-title',[_vm._v(" Elenco utenti "),_c('v-btn',{staticClass:"mx-2",attrs:{"x-small":"","fab":"","dark":"","color":"secondary"},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1),_c('v-spacer'),_c('v-text-field',{staticClass:"search",attrs:{"append-icon":"mdi-magnify","label":"Cerca","single-line":"","hide-details":"","clearable":true},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:`item.actions`,fn:function({ item }){return [(item.attivo == 1)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.modificaUtente(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(item.attivo == 0)?_c('v-btn',{attrs:{"x-small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.riattivaUtente(item)}}},[_vm._v(" Riattiva ")]):_vm._e(),(item.attivo == 1)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.cancellaUtente(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,true)}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.editedIndex === -1 ? "Nuovo utente" : "Modifica utente"))])]),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"autocomplete":"username","label":"Username","maxlength":"11","counter":"11","required":"","readonly":_vm.editedIndex != -1 ? true : false,"rules":[
                        (v) => !!v || 'Campo obbligatorio',
                        (v) => _vm.checkUsername() || 'Username già in utilizzo',
                      ]},on:{"keypress":function($event){return _vm.isInteger($event)}},model:{value:(_vm.editedUser.username),callback:function ($$v) {_vm.$set(_vm.editedUser, "username", $$v)},expression:"editedUser.username"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Email","maxlength":"200","counter":"200","required":"","rules":[
                        (v) => !!v || 'Campo obbligatorio',
                        (v) => /.+@.+\..+/.test(v) || 'E-mail non valida',
                      ]},model:{value:(_vm.editedUser.email),callback:function ($$v) {_vm.$set(_vm.editedUser, "email", $$v)},expression:"editedUser.email"}})],1),(_vm.editedIndex != -1)?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Nuova password","autocomplete":"new-password","append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"rules":[_vm.checkPswd()],"maxlength":"100","counter":"100"},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.editedUser.pswd1),callback:function ($$v) {_vm.$set(_vm.editedUser, "pswd1", $$v)},expression:"editedUser.pswd1"}})],1):_vm._e(),(_vm.editedIndex != -1)?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Ripeti password","autocomplete":"new-password","append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"rules":_vm.editedUser.pswd1
                          ? [(v) => !!v || 'Campo obbligatorio']
                          : [],"maxlength":"100","counter":"100"},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.editedUser.pswd2),callback:function ($$v) {_vm.$set(_vm.editedUser, "pswd2", $$v)},expression:"editedUser.pswd2"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.editTipologia,"item-text":"text","item-value":"value","label":"Tipologia","rules":[(v) => !!v || 'Campo obbligatorio']},model:{value:(_vm.editedUser.tipoutente),callback:function ($$v) {_vm.$set(_vm.editedUser, "tipoutente", $$v)},expression:"editedUser.tipoutente"}})],1)],1)],1)],1)],1),_c('v-card-actions',[(!_vm.validatePswd && _vm.editedUser.pswd1 && _vm.editedUser.pswd2)?_c('div',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("Le due password non corrispondono!")])]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Chiudi ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.validatePswd && _vm.valid ? false : true,"loading":_vm.buttonLoading},on:{"click":function($event){return _vm.salvaUtente()}}},[_vm._v(" Salva ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }