<template>
  <v-container
    id="home"
    style="height: 100%"
    class="d-flex justify-center"
  >
    <div class="home" style="color: #2C3E50;">
      <h1>Benvenuto {{ fornitore }}</h1>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    fornitore: "",
  }),
  mounted() {
    this.fornitore = localStorage.getItem("fornitore");
  },
};
</script>