import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    tipologia: '',
    fornitore: '',
    // url_connessione: 'https://localhost:7227/api/',
    url_connessione: 'https://webservicesfn.arcospedizioni.it/api/',
    fornitori: []
  },
  mutations: {
    destroyToken(state) {
      state.loading = false;
      state.tipologia = '';
      state.fornitore = '';
      state.fornitori = []
      // state.url_connessione = '';
      localStorage.clear();
    },
  },
  actions: {
  },
  modules: {
  }
})
