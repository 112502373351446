<template>
  <v-container style="height: 100%" class="d-flex align-center justify-center">
    <v-row>
      <v-col cols="12">
        <h2>Modifica password</h2>
        <v-form
          ref="form"
          @submit.prevent="invia()"
          class="size-l text-center"
          v-model="valid"
        >
          <v-text-field
            v-model="pswd1"
            label="Nuova password"
            autocomplete="new-password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
            :rules="[
              (v) => !!v || 'Campo obbligatorio',
              (v) =>
                v.length >= 5 ||
                'La password deve essere lunga almeno 5 caratteri!',
            ]"
          ></v-text-field>
          <v-text-field
            v-model="pswd2"
            label="Ripeti password"
            autocomplete="new-password"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"
            :rules="[(v) => !!v || 'Campo obbligatorio']"
          ></v-text-field>
          <div v-if="!validatePswd && pswd1 && pswd2">
            <span style="color: red">Le due password non corrispondono!</span>
          </div>
          <v-btn
            color="primary"
            class="mt-2 mr-2"
            rounded
            outlined
            @click="$router.push('/')"
          >
            Torna al login
          </v-btn>
          <v-btn
            color="primary"
            class="mt-2"
            :disabled="validatePswd && valid ? false : true"
            type="submit"
            rounded
          >
            Invia
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import router from "../router";

export default {
  name: "NuovaPswd",
  data: () => ({
    pswd1: "",
    pswd2: "",
    show1: false,
    show2: false,
    id: null,
    valid: false,
    validatePswd: false,
  }),
  mounted() {
    this.$store.state.loading = true;
    this.id = atob(this.$route.params.id);
    // var idTemp = Buffer.from(this.$route.params.id, "base64");
    // this.id = idTemp.toString();

    //Jwt?
    this.axios
      .post(`${this.$store.state.url_connessione}FoUtentis/check-link`, {
        id_utente: this.id,
      })
      .then((response) => {
        this.$store.state.loading = false;
        if (response.data == "ko") {
          router.push("/");
          this.$root.Snackbar.show({
            text: "Il link non è più valido",
            type: "error",
          });
        }
      })
      .catch((error) => {
        this.$store.state.loading = false;
        if (error.response != undefined) {
          if (error.response.status == 401) {
            this.$root.$emit("logout");
            this.$root.Snackbar.show({
              text: error.response.data,
              type: "error",
            });
          }
        }
      });
  },
  methods: {
    validate() {
      if (this.pswd1 == this.pswd2) {
        this.validatePswd = true;
      } else {
        this.validatePswd = false;
      }
    },
    invia() {
      // router.push("/");
      //   this.$root.Snackbar.show({
      //     text: "Cambio della password effettuato correttamente",
      //     type: "success"
      //   });
      this.$store.state.loading = true;
      this.axios
        .post(`${this.$store.state.url_connessione}FoUtentis/nuova-pswd`, {
          pswd: this.pswd1,
          id_utente: this.id,
        })
        .then((response) => {
          this.$store.state.loading = false;
          if (response.data == "ok") {
            router.push("/");
            this.$root.Snackbar.show({
              text: "Cambio della password effettuato correttamente",
              type: "success",
            });
          }
        })
        .catch((error) => {
          this.$store.state.loading = false;
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout");
              this.$root.Snackbar.show({
                text: error.response.data,
                type: "error",
              });
            }
          }
        });
    },
  },
  watch: {
    pswd1: {
      handler() {
        this.validate();
      },
      immediate: true,
    },
    pswd2: {
      handler() {
        this.validate();
      },
      immediate: true,
    },
  },
};
</script>