var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"id":"fornitori"}},[_c('v-app-bar',{attrs:{"app":"","color":"primary","dark":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{staticClass:"shrink mr-2",attrs:{"alt":"Arco Logo","contain":"","src":require("./assets/LogoArco.png"),"transition":"scale-transition","width":"180"}})],1),_c('v-toolbar-title',[_vm._v("Portale fornitori "+_vm._s(_vm.$store.state.fornitore))])],1),(
      _vm.$route.path != '/' &&
      _vm.$route.path != '/recupera-password' &&
      _vm.$route.name != 'NuovaPswd' &&
      _vm.$route.name != 'PageError'
    )?_c('v-navigation-drawer',{staticStyle:{"height":"calc(100vh - 64px)","margin-top":"64px"},attrs:{"permanent":"","absolute":"","color":"primary","dark":""}},[_c('v-list',[(_vm.$store.state.tipologia == 'S')?_c('v-list-item',{attrs:{"link":"","to":"/utenti"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Utenti")])],1)],1):_vm._e(),(!(_vm.$store.state.tipologia == 'T'))?_c('v-list-item',{attrs:{"link":"","to":"/prefatture"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Prefatture")])],1)],1):_vm._e(),(!(_vm.$store.state.tipologia == 'P'))?_c('v-list-item',{attrs:{"link":"","to":"/trazionisti"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-truck")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Trazionisti")])],1)],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.logout()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Logout")])],1)],1)],1)],1):_vm._e(),_c('v-sheet',{style:(_vm.$route.path != '/' &&
      _vm.$route.path != '/recupera-password' &&
      _vm.$route.name != 'NuovaPswd' &&
      _vm.$route.name != 'PageError'
        ? 'padding: 64px 0 0 256px; height: calc(100vh - 54px)'
        : 'padding-top: 64px; height: calc(100vh - 54px)')},[(_vm.checkHeader)?_c('router-view',{on:{"logout":_vm.logout}}):_vm._e(),(_vm.$store.state.loading)?_c('Loading'):_vm._e(),_c('Snackbar',{ref:"snackbar"})],1),_c('v-bottom-navigation',{staticClass:"d-flex justify-start",attrs:{"fixed":"","background-color":"primary","dark":""}},[_c('span',{staticClass:"d-flex align-center ml-4"},[_vm._v("© 2022 - Arco Spedizioni SpA")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }