<template>
  <v-container id="utenti" style="height: 100%">
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="utenti"
          :items-per-page="itemsPerPage"
          :sort-by="'identificativo'"
          :sort-desc="false"
          :search="search"
          :custom-filter="filterAll"
          multi-sort
          no-data-text="Nessun dato disponibile"
          no-results-text="Nessun risultato"
          fixed-header
          height="calc(100vh - 339px)"
          :footer-props="{
            'items-per-page-options': [15, 25, 50, -1],
            'items-per-page-text': 'Elementi per pagina',
            'items-per-page-all-text': 'Tutti',
          }"
        >
          <template v-slot:top>
            <v-card-title>
              Elenco utenti
              <v-btn
                class="mx-2"
                x-small
                fab
                dark
                color="secondary"
                @click="dialog = true"
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Cerca"
                single-line
                hide-details
                class="search"
                :clearable="true"
              ></v-text-field>
            </v-card-title>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              class="mr-2"
              small
              @click="modificaUtente(item)"
              v-if="item.attivo == 1"
            >
              mdi-pencil
            </v-icon>
            <v-btn
              x-small
              color="primary"
              dark
              @click="riattivaUtente(item)"
              v-if="item.attivo == 0"
            >
              Riattiva
            </v-btn>
            <v-icon small @click="cancellaUtente(item)" v-if="item.attivo == 1">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>

        <!-- DIALOG AGGIUNGI/MODIFICA -->
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{
                editedIndex === -1 ? "Nuovo utente" : "Modifica utente"
              }}</span>
            </v-card-title>

            <v-card-text>
              <v-form ref="form" v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedUser.username"
                        autocomplete="username"
                        @keypress="isInteger($event)"
                        label="Username"
                        maxlength="11"
                        counter="11"
                        required
                        :readonly="editedIndex != -1 ? true : false"
                        :rules="[
                          (v) => !!v || 'Campo obbligatorio',
                          (v) => checkUsername() || 'Username già in utilizzo',
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="editedUser.email"
                        label="Email"
                        maxlength="200"
                        counter="200"
                        required
                        :rules="[
                          (v) => !!v || 'Campo obbligatorio',
                          (v) => /.+@.+\..+/.test(v) || 'E-mail non valida',
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="editedIndex != -1">
                      <v-text-field
                        v-model="editedUser.pswd1"
                        label="Nuova password"
                        autocomplete="new-password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'"
                        @click:append="show1 = !show1"
                        :rules="[checkPswd()]"
                        maxlength="100"
                        counter="100"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="editedIndex != -1">
                      <v-text-field
                        v-model="editedUser.pswd2"
                        label="Ripeti password"
                        autocomplete="new-password"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'"
                        @click:append="show2 = !show2"
                        :rules="
                          editedUser.pswd1
                            ? [(v) => !!v || 'Campo obbligatorio']
                            : []
                        "
                        maxlength="100"
                        counter="100"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select
                        :items="editTipologia"
                        item-text="text"
                        item-value="value"
                        label="Tipologia"
                        v-model="editedUser.tipoutente"
                        :rules="[(v) => !!v || 'Campo obbligatorio']"
                      ></v-select>
                    </v-col>
                    <!-- <v-col cols="12" sm="6">
                      <v-checkbox
                        v-model="editedUser.attivo"
                        label="Attivo"
                        color="secondary"
                      ></v-checkbox>
                    </v-col> -->
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <div v-if="!validatePswd && editedUser.pswd1 && editedUser.pswd2">
                <span style="color: red"
                  >Le due password non corrispondono!</span
                >
              </div>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close()">
                Chiudi
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="validatePswd && valid ? false : true"
                :loading="buttonLoading"
                @click="salvaUtente()"
              >
                Salva
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import router from "../router";

export default {
  name: "Utenti",
  data: () => ({
    utenti: [],
    itemsPerPage: 15,
    headers: [
      {
        text: "Identificativo",
        align: "start",
        value: "id",
      },
      {
        text: "Username",
        value: "username",
      },
      {
        text: "E-mail",
        value: "email",
      },
      {
        text: "Ultimo login",
        value: "last_login",
      },
      {
        text: "Tipologia",
        value: "tipoutente",
      },
      {
        text: "Attivo",
        value: "attivo",
      },
      { text: "Azioni", value: "actions", sortable: false },
    ],
    search: "",
    dialog: false,
    editedUser: {
      id: "",
      username: "",
      email: "",
      pswd1: "",
      pswd2: "",
      tipoutente: "",
      attivo: 0,
      mustChangePassword: 0,
      lastLogin: "",
    },
    editTipologia: [
      { text: "Superadmin", value: "S" },
      { text: "Admin", value: "A" },
      { text: "Padroncino", value: "P" },
      { text: "Trazionista", value: "T" },
      { text: "Utente standard", value: "X" },
    ],
    show1: false,
    show2: false,
    valid: false,
    validatePswd: false,
    editedIndex: -1,
    buttonLoading: false,
  }),
  mounted() {
    this.$store.state.loading = true;
    this.getUtenti();
  },
  methods: {
    validate() {
      if (this.editedUser.pswd1 == this.editedUser.pswd2) {
        this.validatePswd = true;
      } else {
        this.validatePswd = false;
      }
    },
    checkPswd() {
      if (this.editedUser.pswd2) {
        if (!this.editedUser.pswd1) {
          return "Campo obbligatorio";
        } else {
          if (this.editedUser.pswd1.length < 5) {
            return "La password deve essere lunga almeno 5 caratteri!";
          }
          return true;
        }
      } else {
        if (this.editedUser.pswd1 && this.editedUser.pswd1.length < 5) {
          return "La password deve essere lunga almeno 5 caratteri!";
        }
        return true;
      }
    },
    getUtenti() {
      this.axios
        .get(`${this.$store.state.url_connessione}FoUtentis`)
        .then((response) => {
          this.$store.state.loading = false;
          this.utenti = response.data;
          console.log(this.utenti);
        })
        .catch((error) => {
          this.$store.state.loading = false;
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout");
              this.$root.Snackbar.show({
                text: error.response.data,
                type: "error",
              });
            }
          }
        });
    },
    filterAll(valore, search, item) {
      var results = [];
      var columns = [];

      this.headers.forEach((element) => {
        columns.push(element.value);
      });

      Object.entries(item).forEach(([key, value]) => {
        if (typeof value === "number") {
          value = value.toString();
        }

        if (
          value != undefined &&
          value != null &&
          value != "" &&
          columns.includes(key) &&
          search != null &&
          typeof value === "string" &&
          value.toLowerCase().includes(search.toLowerCase())
        ) {
          results.push("true");
        }
      });
      return results.includes("true");
    },
    modificaUtente(item) {
      this.editedIndex = this.utenti.indexOf(item);
      this.editedUser = Object.assign({}, item);
      this.dialog = true;
    },
    isInteger(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    checkUsername() {
      if (
        this.editedUser["username"] != "" &&
        this.editedUser["username"] != null &&
        this.editedUser["username"] != undefined
      ) {
        var check = true;
        this.utenti.forEach((el) => {
          if (
            el.id != this.editedUser.id &&
            el.username == this.editedUser.username.padStart(6, "0")
          ) {
            check = false;
          }
        });
        return check;
      }
      return true;
    },
    salvaUtente() {
      this.buttonLoading = true;

      if (this.editedIndex == -1) {
        this.axios
          .post(`${this.$store.state.url_connessione}FoUtentis/CreaUtente`, {
            username: this.editedUser.username,
            email: this.editedUser.email,
            password:
              this.editedUser.pswd2 == "" ||
              this.editedUser.pswd2 == null ||
              this.editedUser.pswd2 == undefined
                ? ""
                : this.editedUser.pswd2,
            tipoutente: this.editedUser.tipoutente,
            attivo: 1,
            mustChangePassword: 1,
          })
          .then(() => {
            this.buttonLoading = false;
            this.dialog = false;
            this.getUtenti();
            this.$root.Snackbar.show({
              text: `L'utente è stato creato`,
              type: "success",
            });
            this.close();
          })
          .catch((error) => {
            this.buttonLoading = false;
            this.dialog = false;
            this.close();
            if (error.response != undefined) {
              if (error.response.status == 401) {
                this.$root.$emit("logout");
                this.$root.Snackbar.show({
                  text: error.response.data,
                  type: "error",
                });
              }
            }
          });
      } else {
        this.axios
          .put(
            `${this.$store.state.url_connessione}FoUtentis/${this.editedUser.id}`,
            {
              username: this.editedUser.username,
              email: this.editedUser.email,
              password:
                this.editedUser.pswd2 == "" ||
                this.editedUser.pswd2 == null ||
                this.editedUser.pswd2 == undefined
                  ? ""
                  : this.editedUser.pswd2,
              tipoutente: this.editedUser.tipoutente,
              attivo: this.editedUser.attivo,
              id: this.editedUser.id,
              mustChangePassword: this.editedUser.mustChangePassword,
              lastLogin: this.editedUser.lastLogin,
            }
          )
          .then(() => {
            this.buttonLoading = false;
            this.dialog = false
            this.getUtenti();
            this.$root.Snackbar.show({
              text: `L'utente" è stato modificato`,
              type: "success",
            });
            this.close();
          })
          .catch((error) => {
            this.buttonLoading = false;
            this.dialog = false;
            this.close();
            if (error.response != undefined) {
              if (error.response.status == 401) {
                this.$root.$emit("logout");
                this.$root.Snackbar.show({
                  text: error.response.data,
                  type: "error",
                });
              }
            }
          });
      }
    },
    cancellaUtente(item) {
      this.axios
        .put(`${this.$store.state.url_connessione}FoUtentis/${item.id}`, {
          username: item.username,
          email: item.email,
          password: item.password,
          tipoutente: item.tipoutente,
          attivo: 0,
          id: item.id,
          mustChangePassword: item.mustChangePassword,
          lastLogin: item.lastlogin,
        })
        .then(() => {
          this.getUtenti();
          this.$root.Snackbar.show({
            text: `L'utente" è stato disattivato`,
            type: "success",
          });
        })
        .catch((error) => {
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout");
              this.$root.Snackbar.show({
                text: error.response.data,
                type: "error",
              });
            }
          }
        });
    },
    riattivaUtente(item) {
      this.axios
        .put(`${this.$store.state.url_connessione}FoUtentis/${item.id}`, {
          username: item.username,
          email: item.email,
          password: item.password,
          tipoutente: item.tipoutente,
          attivo: 1,
          id: item.id,
          mustChangePassword: item.mustChangePassword,
          lastLogin: item.lastlogin,
        })
        .then(() => {
          this.getUtenti();
          this.$root.Snackbar.show({
            text: `L'utente" è stato riattivato`,
            type: "success",
          });
        })
        .catch((error) => {
          if (error.response != undefined) {
            if (error.response.status == 401) {
              this.$root.$emit("logout");
              this.$root.Snackbar.show({
                text: error.response.data,
                type: "error",
              });
            }
          }
        });
    },
    close() {
      this.dialog = false;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.$nextTick(() => {
        this.editedUser = {
          id: "",
          username: "",
          email: "",
          pswd1: "",
          pswd2: "",
          tipoutente: "",
          attivo: 0,
          mustChangePassword: 0,
          lastLogin: "",
        };
        this.editedIndex = -1;
      });
    },
  },
  watch: {
    "editedUser.pswd1": {
      handler() {
        this.validate();
      },
      immediate: true,
      deep: true,
    },
    "editedUser.pswd2": {
      handler() {
        this.validate();
      },
      immediate: true,
      deep: true,
    },
    dialog(val) {
      val || this.close();
    },
  },
};
</script>