import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import RecuperaPswd from '../views/RecuperaPswd.vue'
import NuovaPswd from '../views/NuovaPswd.vue'
import Home from '../views/Home.vue'
import Utenti from '../views/Utenti.vue'
import Prefatture from '../views/Prefatture.vue'
import Trazionisti from '../views/Trazionisti.vue'
import PageError from '../views/PageError.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: { title: 'Login' }
  },
  {
    path: '/recupera-password',
    name: 'RecuperaPswd',
    component: RecuperaPswd,
    meta: { title: 'Recupera password' }
  },
  {
    path: '/nuova-password/:id',
    name: 'NuovaPswd',
    component: NuovaPswd,
    meta: { title: 'Nuova password' }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { title: 'Homepage' }
  },
  {
    path: '/utenti',
    name: 'Utenti',
    component: Utenti,
    meta: { title: 'Utenti' }
  },
  {
    path: '/prefatture',
    name: 'Prefatture',
    component: Prefatture,
    meta: { title: 'Prefatture' }
  },
  {
    path: '/trazionisti',
    name: 'Trazionisti',
    component: Trazionisti,
    meta: { title: 'Trazionisti' }
  },
  {
    path: '*',
    name: 'PageError',
    component: PageError,
    meta: { title: '404 Not Found' }
  },
]

const router = new VueRouter({
  routes
})

export default router
